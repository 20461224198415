
export function setColor(status) {
    const colors = {
      "on-order-completed": "#5376F6",
      "Aguardando confirmação do seller": "#B30E37",
      "payment-pending": "#DDD33E",
      "approve-payment": "#FF2E63",
      "Aguardando decisão do seller": "#14C3FF",
      "waiting-for-manual-authorization": "#178BB3",
      "window-to-cancel": "#B3948B",
      "ready-for-handling": "#A3D36E",
      handling: "#FF8765",
      "cancellation-requested": "#4D74FF",
      cancel: "#3E58B3",
      canceled: "#EB453B",
      invoiced: "#88C863",
    };
    return colors[status];
}

export function setStatusDescription(status) {
    const text = {
      "processando": "Processando",
      "Aguardando confirmação do seller": "Processando",
      "Aguardando decisão do seller": "Processando",
      "on-order-completed": "Processando",
      "payment-pending": "Pagamento pendente",
      "approve-payment": "Pagamento aprovado",
      "waiting-for-manual-authorization": "Aguardando autorização para despachar",
      "window-to-cancel": "Carência para cancelamento",
      "ready-for-handling": "Pronto para manuseio",
      handling: "Preparando entrega",
      "cancellation-requested": "Cancelamento solicitado",
      cancel: "Iniciando cancelamento",
      canceled: "Cancelado",
      invoiced: "Faturado",
    };
    return text[status];
}