<template>
	<div class="highlight_number">
		<div class="highlight_number__title">{{ title }}</div>
		<div class="highlight_number__value">{{ value | numberWithK }}</div>
	</div>
</template>
   
<script>
import { numberWithK } from "@/utils/money";
export default {
	props: ["title", "value"],
	filters: {
		numberWithK
	}
};
</script>

<style lang="scss">
.highlight_number {

  //   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f07a7a+0,fa4a6b+100 */
  // background: #f07a7a; /* Old browsers */
  // background: -moz-linear-gradient(-45deg,  #f07a7a 0%, #fa4a6b 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(-45deg,  #f07a7a 0%,#fa4a6b 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(135deg,  #f07a7a 0%,#fa4a6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f07a7a', endColorstr='#fa4a6b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f07a7a+0,fa4a6b+100 */
	background: $green; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  $green 0%, $light-blue 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  $green 0%,$light-blue 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  $green 0%,$light-blue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#73cb53', endColorstr='#41a7ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	border-radius: .7rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 1rem;
	min-height: 120px;
	max-width: 120px;
	width: 100%;

	& > * {
		color: white;
		width: 100%;
	}

	&__title {
		font-size: 1rem;
	}

	&__value {
		font-weight: bold;
		font-size: 2rem;
	}
}
</style>