import client from "@/api";
import store from "@/store";
import {
  _rotaCustomers,
  _rotaCustomerDetails,
  _rotaCustomerAddNote,
  _rotaCustomerStar,
  _rotaCustomersOrdersTotal,
  _rotaCustomersOrdersBilling,
  _rotaCustomersCoupons,
  _rotaCustomersRecurrent,
  _rotaCustomersTicketMedio,
  _rotaCustomersRepurchase,
  _rotaCustomersLtv,
} from "@/api/_caminhosApi";

export function fetchOrdersTotal(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_ORDERS_TOTAL", null);
  client
    .get(_rotaCustomersOrdersTotal(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_ORDERS_TOTAL", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchOrdersBilling(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_ORDERS_BILLING", null);
  client
    .get(_rotaCustomersOrdersBilling(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_ORDERS_BILLING", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchCoupons(from, to, qtd, page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_COUPONS", null);
  client
    .get(_rotaCustomersCoupons(), {
      params: {
        from,
        to,
        per_page: qtd,
        page: page,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_COUPONS", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getCouponsCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaCustomersCoupons()}/export`, {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "coupons.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchRecurrent(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_RECURRENT", null);
  client
    .get(_rotaCustomersRecurrent(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_RECURRENT", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchTicketMedio(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_TICKET_MEDIO", null);
  client
    .get(_rotaCustomersTicketMedio(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_TICKET_MEDIO", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchRepurchase(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_REPURCHASE", null);
  client
    .get(_rotaCustomersRepurchase(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_REPURCHASE", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getRepurchaseCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaCustomersRepurchase()}/export`, {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "customers-list.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchLtv(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_LTV", null);
  client
    .get(_rotaCustomersLtv(), {
      params: {
        from,
        to,
      },
    })
    .then((resp) => {
      store.commit("customers/SET_LTV", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getLtvCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaCustomersLtv()}/export`, {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "customers-ltv-list.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchCustomers(from, to, qtd, page) {
  fetchCustomersApi(false, from, to, qtd, page)
}

export function fetchAllCustomers(qtd, page) {
  fetchCustomersApi(true, false, false, qtd, page)
}

export function fetchCustomersApi(all = false, from, to, qtd, page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_LIST", null);

  var apiUrl = (all) ? `${_rotaCustomers()}/all` : _rotaCustomers();

  var params = {
    per_page: qtd,
    page: page,
  };

  if (all == false) {
    params.from = from;
    params.to = to;
  }

  client
    .get(apiUrl, {
      params: params,
    })
    .then((resp) => {
      store.commit("customers/SET_LIST", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchOneCustomer(customerId) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("customers/RESET_DETAILS", null);

  client
    .get(`${_rotaCustomerDetails(customerId)}`)
    .then((resp) => {
      store.commit("customers/SET_DETAILS", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchCustomersCSV(from, to) {
  fetchCustomersCSVApi(false, from, to)
}

export function fetchAllCustomersCSV() {
  fetchCustomersCSVApi(true)
}

export function fetchCustomersCSVApi(all = false, from = null, to = null) {
  store.commit("interacoes/SET_LOADING", true);

  var apiUrl = (all) ? `${_rotaCustomers()}/all/export` : `${_rotaCustomers()}/export`;
  var params = (all == false) ? {
    from,
    to,
  } : {};

  client
    .get(apiUrl, {
      params: params,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "customers-list.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function updateCustomerNotes(customerId, note) {
  const data = {
    note: note,
  };
  client
    .post(`${_rotaCustomerAddNote(customerId)}`, data)
    .then((resp) => {
      console.log('updateCustomerNotes', resp);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function addCustomerStar(customerId) {
  client
    .post(`${_rotaCustomerStar(customerId)}`)
    .then((resp) => {
      console.log('addCustomerStar', resp);
      fetchOneCustomer(customerId);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function removeCustomerStar(customerId) {
  client
    .delete(`${_rotaCustomerStar(customerId)}`)
    .then((resp) => {
      console.log('removeCustomerStar', resp);
      fetchOneCustomer(customerId);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}