export function formatCellphone(cellphone) {
    if (!cellphone) return;

    // Remove all non-digit characters from the cellphone number
    var cleaned = cellphone.replace(/\D/g, '');
    
    // Check if the number has 11 digits (including country and region code)
    if (cleaned.length === 13 || cleaned.length === 12) {
        // Format the cellphone number to Brazilian pattern
        return `(${cleaned.substring(2, 4)}) ${cleaned.substring(4, 9)}-${cleaned.substring(9)}`;
    } else {
        // If the number doesn't have 11 digits, return the original number
        return cellphone;
    }
}