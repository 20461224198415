<template>
  <div class="details-customers-page">
    <b-row>
      <b-col md="12" lg="12" sm="12">
				<div class="box-filter mb-20 mt-5">
					<div>
						<a class="btn-link" href="/customers/list">&larr; Lista de clientes</a>
					</div>
				</div>
			</b-col>
		</b-row>

    <b-row v-if="customer">
      <b-col lg="4" md="5" sm="12">

        <div class="primary pl-20">
          <div class="panel">
						<div class="panel__content mb-20">
							<header class="mt-20 mb-30">
								<h1 class="h1">
									<span class="star-toggle" v-if="customer.starred == 1" @click="removeStar">
										<IconStar :active="(customer.starred == 1)" />
									</span>
									<span class="star-toggle" v-else @click="addStar">
										<IconStar :active="(customer.starred == 1)" />
									</span>
									{{ customer.firstName }} {{ customer.lastName }}
									<small 
										v-if="customer.storeUserId" 
										class="h5 cor-media"
										title="ID do cliente na loja"
          					v-b-tooltip.hover.top
										>#{{ customer.storeUserId }}</small>
								</h1>
							</header>

							<section>
								<h5 class="h5 cor-escura pl-10">Detalhes</h5>
								<table class="table --no-border">
									<tr>
										<td class="cor-media">E-mail</td>
										<td><a :href="`mailto:${customer.email}`" class="btn-link --no-padding">{{ customer.email }}</a></td>
									</tr>
									<tr>
										<td class="cor-media">Telefone</td>
										<td><a :href="`tel:+${customer.cellPhone}`" class="btn-link --no-padding">{{ customer.cellPhone | formatCellphone }}</a></td>
									</tr>
									<tr>
										<td class="cor-media">Aniversário</td>
										<td v-if="customer.birthDate && customer.birthDate != '0000-00-00'">{{ customer.birthDate | formatBirthdate }}</td>
										<td v-else>...</td>
									</tr>
									<tr>
										<td class="cor-media">Corporativo</td>
										<td>{{ (customer.isCorporate) ? 'Sim' : 'Não' }}</td>
									</tr>
									<!-- <tr>
										<td class="cor-media">Endereço</td>
										<td></td>
									</tr> -->
								</table>
							</section>

							<section class="mt-20">
								<!-- <h5 class="h5 cor-escura pl-10">Total de pedidos</h5> -->

								<div class="highlights">

									<HighlightNumber
										title="Total de pedidos"
										:value="customer.ordersCount"
										/>

									<HighlightNumber
										title="Receita total"
										:value="customer.ordersRevenue"
										/>

								</div>
							</section>
							
						</div>
          </div>

        </div>
      </b-col>

      <b-col lg="8" md="7" sm="12">
        <div class="secondary">
					<div class="panel mb-30">
						<div class="panel__content">
							<h5 class="h5 cor-escura">Histórico de pedidos</h5>
							<div>
								<div class="painel">
									<b-table :items="loadTable.items">
										<template #cell(status)="data">
											<span class="table-dot" :style="{ backgroundColor: setColor(data.item.Status.text) }"></span>
											<!-- {{ setStatusDescription(data.item.Status) }} -->
											{{ data.item.Status.description }}
										</template>
										<template #cell(cupom)="data">
											<span class="badge badge-outline">{{ data.item.Cupom }}</span>
										</template>
										<template #cell(data)="data">
											{{ data.item.Data | filterDataHora }}
										</template>										
									</b-table>
								</div>
							</div>
						</div>
					</div>

					<div class="panel">
						<div class="panel__content">

							<b-row>
								<b-col lg="6" md="6" sm="12">
									<section>
										<h5 class="h5 cor-escura mb-10">Notas</h5>
										<textarea v-model="comment" @keyup="editNotes"></textarea>
										<div v-if="edittingNotes" class="btn-download" @click="saveNotes">Atualizar nota</div>
									</section>
								</b-col>
							</b-row>

						</div>
					</div>
				</div>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { mapState } from "vuex";
import IconStar from "@/components/icones/IconStar.vue";
import HighlightNumber from "@/components/highlight/HighlightNumber.vue";
import { numberToRealWitoutSymbol, numberToReal, numberWithK } from "@/utils/money";
import { fetchOneCustomer, updateCustomerNotes, addCustomerStar, removeCustomerStar } from "@/api/customers";
import { formatBirthdate, filterDataHora } from "@/utils/date";
import { formatCellphone } from '@/utils/phone';
import { setColor, setStatusDescription } from "@/utils/status";

export default {
  filters: {
    numberToRealWitoutSymbol,
    numberToReal,
    numberWithK,
		formatBirthdate,
		filterDataHora,
		formatCellphone,
  },
  components: {
    IconStar,
		HighlightNumber,
  },
  data() {
    return {
      userId: null,
			comment: '',
			edittingNotes: false
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.details
    }),
    loadTable() {
      const items = [];
      if (this.customer.orders && this.customer.orders.length > 0) {
        this.customer.orders.map((line) => {
          items.push(
            {
							Id: `#${line.orderId}`,
							Origem: line.integration,
							Data: line.creationDate,
							Método: line.paymentNames,
							Status: {
								text: line.status,
								description: line.statusDescription
							},
              "Qtde. de itens": line.totalItems,
							currencyCode: line.currencyCode,
              Receita: numberToReal(line.totalValue),
            }
          );
        });
      }

      let data = {
        items,
      };
      return data;
    },
	},
	mounted() {
		this.userId = atob(this.$route.params.id);
		this.loadPrincipal();
	},
	methods: {
		fetchOneCustomer,
		updateCustomerNotes,
		addCustomerStar,
		removeCustomerStar,
		setColor,
		setStatusDescription,
		loadPrincipal() {
			this.fetchOneCustomer(this.userId);
		},
		editNotes() {
			this.edittingNotes = true;
		},
		saveNotes() {
			this.updateCustomerNotes(this.userId, this.comment);
		},
		addStar() {
			console.log('addStar');
			this.addCustomerStar(this.userId);
		},
		removeStar() {
			console.log('removeStar');
			this.removeCustomerStar(this.userId);
		}
	},
	watch: {
		customer(data) {
			this.comment = data.notes;
		},
	},
};
</script>

<style lang="scss">
::v-deep {
  .multiselect__placeholder {
    margin-top: 3px;
  }
}
.details-customers-page {
	.star-toggle {
		cursor: pointer;
	}

	.highlights {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}
	.secondary {
    .painel {
      .table {
        // td[aria-colindex="0"],
        // td[aria-colindex="1"] {
        //   font-weight: bold;
        // }
        th[aria-colindex="6"],
        td[aria-colindex="6"] {
          white-space: nowrap;
          text-align: center;
				}
        th[aria-colindex="8"],
        td[aria-colindex="8"] {
          white-space: nowrap;
          text-align: right;
        }
      }
    }
	}
}
</style>
